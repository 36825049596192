import React from "react";

class Headers extends React.Component {
    render() {
        return (
            <div className="container-fluid  ">
                <nav className="navbar navbar-expand-lg navbar-light ">
                    <a className="navbar-brand" href="#">Logo</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse " id="navbarTogglerDemo02">
                        <ul className="navbar-nav mt-2 mt-lg-0 ml-auto">
                            <li className="nav-item active px-sm-1 px-sm-1 px-lg-5">
                                <a className="nav-link text-primary" href="#">Beranda <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item px-sm-1 px-sm-1 px-lg-5">
                                <a className="nav-link" href="#">Tentang Kami</a>
                            </li>
                            <li className="nav-item px-sm-1 px-sm-1 px-lg-5">
                                <a className="nav-link" href="#">Gabung Jadi Mitra</a>
                            </li>
                            <li className="nav-item px-sm-1 px-sm-1 px-lg-5">
                                <a className="nav-link" href="#">Kontak</a>
                            </li>
                        </ul>
                        <div class="btn pr-2">
                            <button className="btn btn-light border-primary mr-1">Daftar</button>
                        </div>
                        <button className="btn btn-primary">Masuk</button>
                    </div>

                </nav>
            </div>
        );
    }
}
export default Headers