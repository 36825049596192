import logo from './logo.svg';
import './App.css';
import Headers from './templates/header.js';
import Footers from './templates/footer';
import Landings from './templates/Landing';

function App() {
  return (
    <div >
      <Headers />
      <Landings />
      <Footers />
    </div >
  );
}

export default App;

