import React from "react";

class Footers extends React.Component {
    render() {
        return (
            <footer>
                <div className="container-fluid bg-primary ">
                    <div className="row py-5 mx-5 align-items-start">
                        <div className="col lg-3 sm-3 md-3 p-2">
                            <h1>Logo</h1>
                            <p className="text-light text-justify">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas harum consectetur corrupti? Eveniet libero reiciendis beatae quisquam modi at error tenetur dolor.
                            </p>
                        </div>
                        <div className="col offset-2 lg-3 sm-3 md-3 p-2">
                            <h3 className="text-light mb-2">Kategori</h3>
                            <ul class="nav flex-column">
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-light">Beranda</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-light">Tentang Kami</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-light">Gabung Jadi Mitra</a></li>
                                <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-light">Kontak</a></li>

                            </ul>
                        </div>
                        <div className="col offset-2 lg-3 sm-3 md-3 p-2 ">
                            <h3 className="text-light mb-2">Berlangganan</h3>
                            <div className="input-group">
                                <input type="text" className="form-control bg-primary " id="inlineFormInputGroup" placeholder="Email@mail.com"></input>
                                <div className="input-group-append">
                                    <div className="input-group-text"><button className="btn"> <span>/ </span></button></div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <hr className="mb-3 bold"></hr>
                </div>
            </footer>

        );
    }
}
export default Footers