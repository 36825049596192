import React from "react";
import background from "../component/bg.jpg";
import erik from "../component/erik.jpg";
import beler from "../component/beler.jpg";
class Landings extends React.Component {
    render() {
        return (
            <section>
                {/* head */}
                <div className="jumbotron koper" style={{ backgroundImage: `url(${background})`, height: 500 }}>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <h1 className="display px-5 mt-10"> Welcome to</h1>
                    <h1 className="display px-5 mt-10"> Cetakuy</h1>
                    <hr className="text-white"></hr>
                    <div className="row">
                        <div className="col-lg-5 hitam">
                            <h3 className=" px-5 display text-white">Cetak mudah dengan Cetakuy.</h3>
                        </div>
                    </div>


                </div>

                {/* about */}
                <div className="container">
                    <div className="row py-5">
                        <div className="col-lg-4 offset-1 center">
                            <img className="about" src={erik}></img>
                        </div>
                        <div className="col-lg-7 text-justify m-auto">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta in nihil porro consequatur quasi accusamus sunt, eos veritatis. Culpa voluptatibus enim consequuntur deserunt libero temporibus quisquam quod nam deleniti praesentium.
                        </div>
                    </div>
                </div>

                {/* kontol */}
                <div className="ssf">
                    <div className="container ssf">
                        <div className="row py-5">

                            <div className="col-lg-7 text-justify m-auto">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta in nihil porro consequatur quasi accusamus sunt, eos veritatis. Culpa voluptatibus enim consequuntur deserunt libero temporibus quisquam quod nam deleniti praesentium.
                            </div>
                            <div className="col-lg-4 offset-1 center">
                                <img className="about" src={beler}></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* bereslah */}
                <div className="d-flex ">
                    <div className="container mt-5">
                        <h2 className="display-5 mt-5 mb-5 text-center"> bergabung bersama kami</h2>
                        <p className="display-6 px-5 mb-5 text-center"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis inventore velit sit ipsa. Molestias inventore ab, asperiores aliquid, amet recusandae assumenda nulla magni, illo doloribus excepturi? Similique sunt tempore porro. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam amet veniam ea provident in, natus, nam ut vel ipsam recusandae officiis inventore sint cum, culpa omnis ab voluptatibus quia pariatur.</p>
                        <div className="row">
                            <div className="col text-center">
                                <button className="btn btn-primary mb-5"> Selengkapnya </button>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}
export default Landings